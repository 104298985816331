
import { Avatar, Box, Container, Grid, Typography } from "@mui/material"
import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR,NORMAL_TEXT_COLOR } from "../../app/config"

const TeamItem = (props) => {
    const { icon, name, title, description } = props
    return <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: 'center', m: 2 }}>
        <Avatar sx={{ bgcolor: LOGO_ICON_COLOR, color: "#fff", width: "80px", height: "80px" }} src={icon}></Avatar>
        <Typography variant="h6" sx={{ color: LOGO_TEXT_COLOR, mt: 1 }}>{name}</Typography>
        <Typography variant="body1" sx={{ color: NORMAL_TEXT_COLOR }}>{title}</Typography>
        <Typography variant="body1" sx={{ color: NORMAL_TEXT_COLOR }} align="center" component={"div"}>{description}</Typography>
    </Box>
}
const teams = [
    { icon: "/team/tony.jpg", name: "Tony Qian", title: "Director", description: "Robotics Head Coach" },
    { icon: "/team/june.png", name: "June Jiao", title: "Director", description: "Senior Robotics Teacher" },
    { icon: "/team/ljw.png", name: "Leo Liang", title: "Director", description: "Senior Computer Engineer" },    
    { icon: "/team/du.png", name: "Dudu", title: "Director", description: "Senior Education Expert" },
    { icon: "/team/rebecca.png", name: "Rebecca Li", title: "Admission Advisor", description: "Senior K-12 Education Planner" },
    { icon: "/team/ela.png", name: "Mihaela", title: "Competition Math Head Coach", description: "B.Sc., B.Ed., M.Sc." },
    { icon: "#", name: "Lydia Liu", title: "Senior Math Lecturer", description: "Senior University Lecturer" },    
    { icon: "/team/betty.png", name: "Betty", title: "Senior Lecturer", description: "Senior Chinese Tutor" },    
    { icon: "/team/noah.png", name: "Noah Pinno", title: "Senior Coach", description: "EEC Debate Coach" },        
    { icon: "/team/dylan.png", name: "Dylan Liu", title: "Lecturer", description: "University of Toronto" },
    { icon: "/team/tim.png", name: "Tim Yan", title: "Lecturer", description: "University of Waterloo" }
]    
const About = (props) => {
    return (
        <Box sx={{ width: "100%", bgcolor: "#fff" }}>
            <Container maxWidth="lg" id="about">
                <Box sx={{ flexDirection: "column", display: 'flex', alignItems: "center", pt: 8 }}>
                    <Typography variant="body1" align="center" sx={{ color: NORMAL_TEXT_COLOR }}>ABOUT US</Typography>
                    <Typography variant="h3" align="center" sx={{ color: LOGO_TEXT_COLOR, fontWeight: "bold", mt: 2 }}>Strong sense of mission</Typography>
                    <Typography variant="h6" align="center" sx={{ mt: 4, color: NORMAL_TEXT_COLOR }}>With the rapid development of technology, more and more parents are beginning to pay attention to how their children can achieve success in the future. We deeply understand that no matter how the future changes, a child's cognition, logic, and language will be the most basic abilities that must be mastered. Through Techblazers, we combine the three most basic subjects of mathematics, robotics and programming, and language to comprehensively exercise children's cognition and logic as well as their strong communication skills, preparing them for the future. We hope to learn and explore together with the children.</Typography>
                    <Typography variant="h3" align="center" sx={{ color: LOGO_TEXT_COLOR, fontWeight: "bold", mt: 8 }}>Our team</Typography>
                    <Grid container sx={{ mt: 4, pb: 8 }}>
                        {teams.map((item,idx) => (
                            <Grid item xs={6} key={idx} md={3}>
                                <TeamItem icon={item.icon} name={item.name} title={item.title} description={item.description} key={item.title} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}
export default About