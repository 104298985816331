import { Alert, Box } from "@mui/material"
import { useEffect, useState } from "react"
import { NewClassLoader } from "../api/loader"
import CodeSchedule from "./fragement/CodeSchedule"
import DebateSchedule from "./fragement/DebateSchedule"
import IntensiveMath from "./fragement/IntensiveMath"
import MathSchedule from "./fragement/MathSchedule"
// import OtherSchedule from "./fragement/OtherSchedule"
import PsSchedule from "./fragement/PsSchedule"
import RWSchedule from "./fragement/RWSchedule"

const Schedule = () => {
    const [error,setError] = useState()
    const [schedules,setSchedules] = useState()
    
    useEffect(()=>{
        
        NewClassLoader((data)=>{
            setSchedules(data)
        },setError)
    },[])
    return <Box>
        {error && <Alert severity="error" onClose={()=>setError()}>{error}</Alert>}     
        <CodeSchedule schedules={schedules}/>
        <IntensiveMath  schedules={schedules}/>
        <MathSchedule  schedules={schedules}/>
        <RWSchedule schedules={schedules} />
        <PsSchedule schedules={schedules} />
        <DebateSchedule schedules={schedules} />
        {/* <OtherSchedule schedules={schedules} /> */}
        {/* <OtherSchedule  schedules={schedules}/> */}
    </Box>
}

export default Schedule