import { Button, styled } from "@mui/material";
import { useNavigate } from "react-router";

const ContainedButton = styled(Button)`  
  background-color:#D5000F;
  :hover {
    background-color: #D5000F88;    
  }
`;

const OutlinedButton = styled(Button)`  
  border-color:#ffffff;
  color:#fff;
  :hover {
    background-color: #fff4; 
    border-color:#fff;   
  }
`;

const NavigateButton = (props)=>{
    const {variant,url,text} = props
    const navigate = useNavigate()
    return variant == "contained"?<ContainedButton variant={variant} onClick={()=>{navigate(url)}} sx={props.sx}>{text}</ContainedButton>:
    <OutlinedButton variant={variant} onClick={()=>{navigate(url)}} sx={{...props.sx,width: "160px", mr: 2 }}>{text}</OutlinedButton>
}
export {OutlinedButton,ContainedButton,NavigateButton}