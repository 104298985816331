import { Alert, Box,  Fab, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { apiResult, cacheGet, cacheSet } from '../Utils/Common';
import apis from '../api';
import { useEffect, useState } from 'react';
import { ContainedButton } from '../Component/MyButtons';

const urlAdTag = "2025SPRING_ENROLLMENT"
const AdTraceInitForm = (props) => {
    const {token,uid,onClose,open} = props
    const [_open, setOpen] = useState(open)
    const [error,setError] = useState()    
    const [myID,setMyID] = useState(uid)
    const [fields,setFields] = useState({})
    const [language, setLanguage] = useState(''); 
    const [total,setTotal] = useState(0)
    const [count,setCount] = useState(0)
    let loading = false
    useEffect(() => {         
        setLanguage(navigator.language || navigator.userLanguage)   
        console.log(navigator.language || navigator.userLanguage)
        if(myID !=0 && !loading) {
            apis.adTokenGet(myID,token).then(ret=>{
                apiResult(ret,data=>{
                    setTotal(data.total)
                    setCount(data.count)
                })
            })
        }    
        loading = true
    },[])
    const handleClose = () => {
        setOpen(false)        
    }
    const handleChange = (k,v)=>{
        setFields({...fields,[k]:v})
    }
    const handleSave = (e)=>{                
        apis.adTokenPut({...fields,myToken:token}).then(ret=>{            
            apiResult(ret,data=>{
                setMyID(data)
                onClose && onClose(data)
            },setError)
        })
    }
    return _open ?<Snackbar open={true} onClose={handleClose} anchorOrigin={{ vertical:"bottom", horizontal:"right"}}>
        {language != "zh-CN" ? <Alert onClose={handleClose} sx={{ width: '100%',bgcolor:"#F8C3C3" }}>            
            <Box sx={{maxWidth: '250px',color:"#000000"}}>
                <Typography variant='h6' sx={{mb:1}}>Spring 2025 enrollment</Typography>
                <Typography variant='subtitle1' sx={{mb:0.5}}>Pass-the-Parcel Tuition Earning</Typography>
                <Typography variant='body2' sx={{mb:0.5}}>Share our Spring 2025 enrollment information webpage with your friends. When your friends open the link, you can earn $2 to $5 in tuition credits, up to a maximum of $50.</Typography>
                <Typography variant='body2' sx={{mb:1}}>Share easily, spread knowledge!!</Typography>
                {myID == 0 && <Box>
                    <TextField onChange={(e)=>{handleChange("name",e.target.value)}} size="small" variant="standard" name="name" id="name" sx={{mb:1}} label="Your Name" fullWidth required={true}/>
                    <TextField onChange={(e)=>{handleChange("email",e.target.value)}} size="small" variant="standard" name="email" id="email" sx={{mb:2}} label="Email" fullWidth required={true}/>                
                    <ContainedButton variant={"contained"} onClick={handleSave} sx={{mb:2}} fullWidth>Join Event</ContainedButton>
                </Box>}                
                {myID != 0 && <Box>
                    <Typography variant='h6' sx={{color:"#D5000F",mt:1,mb:1}}>You have earned <b>${total}</b> in tuition fees. <b>{count}</b> friends have clicked on your share, thanks.</Typography>
                    <Typography variant='subtitle1' sx={{mb:1,fontWeight:"bold"}}>Share methods:</Typography>
                    <Typography variant='body2' sx={{mb:0.5}}>1. Copy the website link and share it with your friends via email, text message, or social media tools.</Typography>
                    <Typography variant='body2' sx={{mb:0.5}}>2. On mobile devices, you can directly share to WeChat, Facebook etc., using the browser's sharing feature, more people will click your link.</Typography>                    
                </Box>}
                {error && <Typography variant='body2' sx={{color:"red",mb:1}}>{error}</Typography>}
            </Box>
        </Alert>:
        <Alert onClose={handleClose} sx={{ width: '100%',bgcolor:"#F8C3C3" }}>            
            <Box sx={{maxWidth: '250px',color:"#000000"}}>
                <Typography variant='h6' sx={{mb:1}}>2025年春季招生</Typography>
                <Typography variant='subtitle1' sx={{mb:0.5}}>击鼓传花赚学费</Typography>
                <Typography variant='body2' sx={{mb:0.5}}>将我们2025年招生链接分享给朋友，朋友点击即可赚<b> 2 ~ 5 </b>加元学费，最多可赚<b> 50 </b>加元学费哦！</Typography>
                <Typography variant='body2' sx={{mb:1}}>简单分享，传递知识</Typography>
                {myID == 0 && <Box>
                    <TextField onChange={(e)=>{handleChange("name",e.target.value)}} size="small" variant="standard" name="name" id="name" sx={{mb:1}} label="您的名字" fullWidth required={true}/>
                    <TextField onChange={(e)=>{handleChange("email",e.target.value)}} size="small" variant="standard" name="email" id="email" sx={{mb:2}} label="您的邮箱" fullWidth required={true}/>                
                    
                    <ContainedButton variant={"contained"} onClick={handleSave} sx={{mb:2}} fullWidth>加入活动</ContainedButton>
                </Box>}                
                {myID != 0 && <Box>
                    <Typography sx={{color:"#D5000F",mt:1,mb:1}}>你已经赚了 <b>${total}</b> 学费，有 <b>{count}</b> 个朋友点击了你的分享，谢谢.</Typography>
                    <Typography variant='subtitle1' sx={{mb:1,fontWeight:"bold"}}>分享方式:</Typography>
                    <Typography variant='body2' sx={{mb:0.5}}>1. 打开并复制网站招生页面链接，通过任何方式发送给朋友.</Typography>
                    <Typography variant='body2' sx={{mb:1}}>2. 在手机上点击浏览器菜单中分享功能，分享给朋友微信或发送微信朋友圈等</Typography>                    
                </Box>}
                {error && <Typography variant='body2' sx={{color:"red",mb:1}}>{error}</Typography>}
            </Box>
        </Alert>}
    </Snackbar>:<Fab color={"primary"} onClick={()=>setOpen(true)} sx={(theme) => ({":hover":{bgcolor:"#D5000F88"},color:"white",bgcolor:"#D5000F",position: 'fixed',bottom: theme.spacing(2),right: theme.spacing(2),})}>{language != "zh-CN"?"2025":"赚学费"}</Fab>
}
const adTrace = (setInitForm) => {
    const url = new URL(window.location.href)

    const reloadPage = (url, token) => {
        const jsonToQueryString = (json) => {
            return Object.keys(json).map(key =>
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
            ).join('&');
        }
        if (url.search == "") {
            window.history.replaceState(null,"",url.origin + url.pathname + "?aid=" + token + "&atime=" + Date.now() +(url.hash?url.hash:""))
        } else if (url.search != "") {
            const queryParams = Object.fromEntries(url.searchParams.entries())
            queryParams["aid"] = token
            queryParams["atime"] = Date.now()
            window.history.replaceState(null,"",url.origin + url.pathname + "?" + jsonToQueryString(queryParams)+(url.hash?url.hash:""))
        }
    }
    const urlAdToken = url.searchParams.get("aid")
    const urlAdTime = url.searchParams.get("atime")
    const myTokenKey = urlAdTag + "_MyToken_"
    const myIDKey = urlAdTag + "_MyToken_ID"
    const adTokenKey = urlAdTag + "_AdTokens_"
    let myToken = cacheGet(myTokenKey)
    let myID = cacheGet(myIDKey,0)
    const adTokens = cacheGet(adTokenKey, [])
    const adFormAlert = cacheGet(urlAdTag + "_form_times",0)
    if (!myToken || (urlAdToken && urlAdToken != myToken && adTokens.indexOf(urlAdToken) == -1)) {
        apis.adTokenPost({ urlAdToken: urlAdToken != null ? urlAdToken : undefined, myToken, urlAdTime: urlAdTime != null ? urlAdTime : undefined, url: url.origin + url.pathname, tag: urlAdTag }).then(ret => {
            apiResult(ret, data => {
                if (data.trace == 1 && urlAdToken) {
                    adTokens.push(urlAdToken)
                    cacheSet(adTokenKey, adTokens)
                }
                if (!myToken && data.my) {
                    cacheSet(myTokenKey, data.my)
                    myToken = data.my
                }
                reloadPage(url, myToken)
                setInitForm && setInitForm(<AdTraceInitForm open={adFormAlert<3} onClose={(id)=>{cacheSet(myIDKey,id);myID = id;}} token={myToken} uid={myID}/>)
                cacheSet(urlAdTag + "_form_times",adFormAlert+1)
            }, (err) => { console.error(err) })
        })
    } else {
        if (myToken && (urlAdToken == null || urlAdToken != myToken)) {
            reloadPage(url, myToken)
        }
        if (myToken) {
            setInitForm && setInitForm(<AdTraceInitForm open={adFormAlert<3} onClose={(id)=>{cacheSet(myIDKey,id);myID = id;}} token={myToken} uid={myID}/>)
            cacheSet(urlAdTag + "_form_times",adFormAlert+1)
        }    
    }
    return myToken
}

export { adTrace }