import { Box, Container, Grid, Typography } from "@mui/material"
import { BACKGROUND_COLOR_1, LOGO_ICON_COLOR, LOGO_TEXT_COLOR, NORMAL_TEXT_COLOR } from "../../app/config"
import { NavigateButton } from "../../Component/MyButtons"
import { ScheduleTable } from "./ScheduleTable"

const CourseSchedule = (props) => {
    const { course, index } = props
    
    return <Box sx={{ width: "100%", bgcolor: index % 2 == 0 ? BACKGROUND_COLOR_1 : "white", pt: { xs: 8, md: 12 }, pb: { xs: 8, md: 12 } }}>
        <Container maxWidth="lg" id="mainblock">
            <Grid container>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap", alignItems: "left", }}>
                        <Typography variant="h4" component="div" sx={{ color: LOGO_TEXT_COLOR, mb: "14px", fontWeight: "bold" }}>
                            {course.name}<br></br>for <font color={LOGO_ICON_COLOR}>{course.target}.</font>
                        </Typography>
                        {
                            course.description && course.description.length > 0 && JSON.parse(course.description)?.map((t, idx) => (
                                <Typography key={idx} variant="h6" component="div" sx={{ color: NORMAL_TEXT_COLOR,fontSize:"16px" }}>{t}</Typography>
                            ))
                        }
                        <Box sx={{ flexDirection: "row", alignItems: "flex-start", display: "flex", mt: 2 }}>
                            <NavigateButton variant="contained" url={"/register"} sx={{ width: "160px", mr: 2 }} text="Register" />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box sx={{ mt: { xs: 4, md: 0 }, ml: { xs: 0, md: 4 }}}>
                        <ScheduleTable showHead={true} sx={{fontSize:"14px"}} title={course.name} datas={course.class_news} />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box>
}

export default CourseSchedule