
import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router"
import { getUserSession } from "../Utils/Common"
import Tail from "./Tail"
import TopBar from "./TopBar"
import { adTrace } from "../app/AdTrace"

const Layout = () => {
    useNavigate()
    const session = getUserSession()
    const [adform, setAdForm] = useState()
    let loading = false
    useEffect(() => {
        
        if (session && session.email_verified == 0 && window.location.pathname.substring(0, 5) != '/user') {
            window.location.href = '/user/emailverify'
            return
        } else if (window.location.href.indexOf("#") < 0) {
            window.scrollTo(0, 0)
        }
        if(window.location.href.indexOf("2025enrollment")>=0 || window.location.href.indexOf("schedule")>=0 || window.location.href.indexOf("register")>=0) {
            if(loading) return
            loading = true
            adTrace((ff)=>setAdForm(ff))
        }else {
            setAdForm()
        }
    }, [window.location.href])
    return <Box>
        
        <TopBar />
        <Box sx={{ minHeight: "100px" }}><Outlet sx={{ minHeight: "400px" }} /></Box>
        {adform}
        <Tail />
    </Box>
}

export default Layout