import { Box, Container, Grid, Stack, Typography } from "@mui/material"
import { LOGO_ICON_COLOR } from "../../app/config"
import { useState } from "react"
const Enrollment = (props) => {
    const [detail,setDetail] = useState()
    const copyToClipboard = () => { 
        window.navigator.clipboard.writeText(window.location.href).then(() => { 
            window.alert('The webpage address has been copied to the clipboard.'); }
        ).catch(err => { window.alert('Copy failed: ', err); })
    }
    return <Box sx={{width:"100%",height:{xs:detail?"700px":"500px",sm:detail?"500px":"350px"},pt:"50px",pb:"50px",bgcolor:LOGO_ICON_COLOR}}>
        <Container maxWidth={"lg"}>
            <Stack direction={"row"}>
                <Box sx={{pt:5,pb:5,color:"white"}}>
                    <Typography variant="h5" sx={{mb:0.5,color:"#bbb"}}>2025 Spring Enroll Now</Typography>
                    <Typography variant="h4" sx={{mb:0.5,fontWeight:"bold"}}>Pass-the-Parcel Tuition Earning</Typography>
                    <Typography variant="h5" sx={{mt:2,mb:2,fontWeight:"bold"}}>Share easily, spread knowledge!</Typography>
                    {!detail && <Typography variant="h6" sx={{mb:2}}>Share our Spring 2025 enrollment information with your friends. When your friends open the link, you can earn $2 to $5 in tuition credits, up to a maximum of $50.</Typography>}
                    {detail && <Box sx={{mb:2}}>

                        <Typography variant="h6">How to share:</Typography>
                        <Typography variant="subtitle1">1. Copy the current page address and share with your friends, <a style={{color:"orange",cursor:"pointer",fontWeight:"bold"}} onClick={()=>copyToClipboard()}>Click to copy</a></Typography>
                        <Typography variant="subtitle1">2. On mobile devices, you can share this page to WeChat, Facebook etc., using the browser's sharing feature.</Typography>
                        <Typography variant="h6">How to earn:</Typography>
                        <Typography variant="subtitle1">When the link you share with your friends is opened by them, the system will automatically accumulate points for you. 
                        <br></br>1.You will earn 5 points if a new user opens your link. 
                        <br></br>2.You will earn 2 points if a user who has already participated in the game but has not been invited by others opens your link. 
                        <br></br>3.You will earn 1 points if a user who has been invited by others opens your link. </Typography>                        
                    </Box>}
                    <Typography variant="subtitle1" sx={{cursor:'pointer'}} onClick={()=>{setDetail(!detail)}}>{!detail ?"Click to know detail >":"< Back"}</Typography>
                </Box>
                {!detail && <Box sx={{pl:6,pt:2,display:{xs:'none',sm:"none",md:'block'}}}>
                    <img src="/2025enroll.jpg" style={{width:"80%",height:"auto",borderRadius:"200px"}} ></img>
                </Box>}
            </Stack>
        </Container>
    </Box>
}

export default Enrollment