import { Alert, Box } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { NewClassLoader } from "../api/loader"
import CodeSchedule from "./fragement/CodeSchedule"
import DebateSchedule from "./fragement/DebateSchedule"
import IntensiveMath from "./fragement/IntensiveMath"
import MathSchedule from "./fragement/MathSchedule"
// import OtherSchedule from "./fragement/OtherSchedule"
import PsSchedule from "./fragement/PsSchedule"
import RWSchedule from "./fragement/RWSchedule"
import OtherSchedule from "./fragement/OtherSchedule"
import apis from "../api"
import { apiResult } from "../Utils/Common"
import CourseSchedule from "./fragement/CourseSchedule"
import Enrollment from "./fragement/Enrollment"


const Courses = () => {
    const [error,setError] = useState()
    const [courses,setCourses] = useState()
    const loading = useRef(false)
    document.title = "2025 Spring Enrollment Events"
    useEffect(()=>{
       if(!loading.current) {
            loading.current = true
            apis.loadCourses(0,1).then(ret=>{
                apiResult(ret,data=>{
                    setCourses(data)
                },setError)
            })
       }
    },[])
    return <Box>
        {error && <Alert severity="error" onClose={()=>setError()}>{error}</Alert>}     
        <Enrollment />
        {courses?.map((course,idx)=>{
            return <CourseSchedule course={course} key={idx} index={idx+1} />
        })}        
    </Box>
}

export default Courses