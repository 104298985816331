import { Alert, Box, Checkbox, Container, FormControlLabel, Grid, Paper, TextField, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import apis from "../api"
import { LOGO_ICON_COLOR,  LOGO_TEXT_COLOR } from "../app/config"
import { Selection } from "../Component/MuiEx"
import { ContainedButton } from "../Component/MyButtons"
import { apiResult } from "../Utils/Common"

import { ScheduleTable } from "./fragement/ScheduleTable"

const CourseRegister = () => {
    const [parent, setParent] = useState({})
    const [kid, setKid] = useState({})
    const [choices,setChoices] = useState({})
    const [error, setError] = useState()
    const [hint, setHint] = useState()
    // const [courses,setSchedules] = useState()
    const [changed,setChanged] = useState(false)
    const [courses,setCourses] = useState()
    const loading = useRef(false)
    const code = useRef(String(new Date().getTime()))
    document.title = "2025 Spring Enrollment Events"
    useEffect(()=>{
       if(!loading.current) {
            loading.current = true
            apis.loadCourses(0,1).then(ret=>{
                apiResult(ret,data=>{
                    setCourses(data)
                },setError)
            })
       }
    },[])
    const handleChecked = (id,cid,checked) => {
        setChanged(true)
        let cc = {...choices}
        if(cc["course-"+id] && cc["course-"+id].indexOf(cid)>=0 && !checked) {
            let i = cc["course-"+id].indexOf(cid)
            cc["course-"+id].splice(i,1)
        }else if((!cc["course-"+id] || cc["course-"+id].indexOf(cid)<0) && checked){
            if(!cc["course-"+id]) cc["course-"+id] = [cid]
            else cc["course-"+id].push(cid)
        }
        setChoices(cc)
    }
    const handleCourseSelected = (id,ids) =>{
        setChanged(true)
        let cc = {...choices}
        cc["course-"+id] = ids
        setChoices(cc)
    }
    const handleSubmit = (e) => {
        setError()
        setHint()
        let cc = []
        for(let k of Object.keys(choices)) {
            for(let id of choices[k]) {
                cc.push(id)
            }
        }      
        kid.classes = cc  
        let data = {...parent,kids:[kid],code:code.current}
        apis.postClassApplicant(data).then(ret=>{
            apiResult(ret,data=>{                
                setChanged(false)
                setHint("Submitted successfully, please complete the payment in time to reserve the spot")
                window.alert("Submitted successfully, please complete the payment in time to reserve the spot")
            },setError)
        })
        e.preventDefault();
    }
    const handleParentChange = (k, v) => {
        setChanged(true)
        setParent({...parent,[k]:v})
    }
    const handleKidChange = (k, v) => {
        setChanged(true)
        setKid({...kid,[k]:v})
    }
    return (<Container maxWidth="lg">
        <Box sx={{ display: "flex", mb: 8, flexDirection: "column", width: "100%", flexWrap: "wrap", }}>
            <Typography variant="h2" component="div" sx={{ zIndex: 100, width: "100%", display: { xs: "none", md: "flex" }, color: LOGO_ICON_COLOR, mt: 10, pl: { xs: 2, md: 8 }, fontWeight: "bold" }}>
            Spring 2025 Enrollment
            </Typography>
            <Box sx={{ width: "100%", mt: { xs: 0, md: -32 } }}><img src="/register.png" width="100%" /></Box>
            <Grid container spacing={4} sx={{mt:2 }}>
                <Grid item xs={12} md={7}>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", flexWrap: "wrap", }}>
                        <Typography variant="h4" sx={{ color: LOGO_TEXT_COLOR }}>Choose courses</Typography>
                        {courses && courses.map((course, idx) => {
                            return <Box key={idx} sx={{ mt: 2 }}>
                                <Typography variant="h6">{course.name}</Typography>                                
                                <Paper key={idx} sx={{ mt: 1, mb: 1, p: 2 }}>
                                    <ScheduleTable sx={{ display: { xs: "none", md: "block" } }} showHead defaultSelected={choices["course-"+course.id]} onSelected={(ids)=>handleCourseSelected(course.id,ids)} datas={course.class_news} />
                                    <Box sx={{ display: { xs: "block",md:"none"} }}>
                                        <Typography variant="body1">{course.name}</Typography>
                                        {course.class_news.map((p, idx1) => {                                                                                           
                                            return <FormControlLabel sx={{mt:{xs:1,md:0}}} key={idx1}
                                                control={<Checkbox color="primary" onChange={(e)=>handleChecked(course.id,p.id,e.target.checked)} checked={choices["course-"+course.id] && choices["course-"+course.id].indexOf(p.id)>=0?true:false} />}
                                                label={<Typography variant='body2'>{p.level} {p.wdate} ({p.times}) {p.lessons} lessons ${p.price} - Availbale {p.opencount}</Typography>} />
                                        })}
                                    </Box>
                                </Paper>                                
                            </Box>
                        })}
                    </Box>
                </Grid>
                <Grid item xs={12} md={5} >
                    <Box sx={{ mt: {xs:2,md:0},position: '-webkit-sticky',position: 'sticky',top: 80,bottom: 20,}}>
                        <Typography variant="h4" sx={{ color: LOGO_TEXT_COLOR }}>Applicant Information</Typography>
                        <Box component="form" onSubmit={(e) => { handleSubmit(e) }} sx={{ mt: 1 }}>
                            <Paper sx={{ p: 2, mb: 2 }}>
                                <Typography variant="body1" sx={{ color: LOGO_TEXT_COLOR }}>Parent Information</Typography>
                                <TextField margin="normal" required fullWidth onChange={(e) => { handleParentChange("name", e.target.value) }} label="Parent name" />
                                <TextField margin="normal" required fullWidth onChange={(e) => { handleParentChange("email", e.target.value) }} label="Email Address" />
                                <TextField margin="normal" required fullWidth onChange={(e) => { handleParentChange("phone", e.target.value) }} label="Phonenumber" />

                            </Paper>
                            <Paper sx={{ p: 2, mb: 2 }}>
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <Typography variant="body1" sx={{ color: LOGO_TEXT_COLOR, flex: '1 1 10%' }}>Child Information</Typography>
                                </Box>
                                <TextField margin="normal" required fullWidth label="Name" onChange={(e) => { handleKidChange("name", e.target.value) }} />
                                <Selection required sx={{ width: "100%", mt: { md: 2, xs: 3 } }} label="Select Gender" onChange={(n, v) => handleKidChange("gender", v)} items={["Boy", "Girl", "Other"]} />
                                <TextField margin="normal" required fullWidth label="School" onChange={(e) => { handleKidChange("school", e.target.value) }} />
                                <Selection required sx={{ width: "100%", mt: { md: 2, xs: 2 } }} label="Select Grade" onChange={(n, v) => handleKidChange("grade", v)} items={["Kindergarten","Grade 1","Grade 2", "Grade 3", "Grade 4", "Grade 5", "Grade 6", "Grade 7", "Grade 8", "Grade 9", "Grade 10", "Grade 11", "Grade 12"]} values={[0,1,2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} />                                
                            </Paper>
                            {error && <Alert severity="error" onClose={()=>setError()} sx={{mt:2}}>{error}</Alert>}
                            {hint && <Alert severity="success" onClose={()=>setHint()} sx={{mt:2}}>{hint}</Alert>}                            
                            <ContainedButton type="submit" variant="contained" disabled={!changed} sx={{ mt: 3,width: "160px", mb: 2 }} > Register </ContainedButton>
                            <Paper sx={{p:2}}>
                                <Typography variant="h6">Important Information</Typography>
                                <Typography variant="body1" sx={{mt:1}}>1. The course schedule may be adjusted, we will communicate with you in time.</Typography>
                                <Typography variant="body1" sx={{mt:1}}>2. Registration is complete after payment, you can pay by cash or EMT (<font color={LOGO_ICON_COLOR}>admin@codefuture.ca</font>).</Typography>
                                {/* <Typography variant="body1" sx={{mt:1}} >3. Early Bird: <font color={LOGO_ICON_COLOR}>10%</font> off until May 30, <font color={LOGO_ICON_COLOR}>5%</font> off until June 30.</Typography> */}
                                <Typography variant="body1" sx={{mt:1}}>3. You can canel the course and refund the remaining course fee before three lessons(inclusive). If you canle the course after three lessons, the remaining course fee will be converted into Credit to pay for other choices.</Typography>                                
                                <Typography variant="body1" sx={{mt:1}}>4. Only one absence credit is provided per course. We generally do not provide make-up classes, but provide class materials and homework. We'll provide as much help as possible to help children keep up with the course.</Typography>                                
                            </Paper>
                            {/* <Alert severity="info" sx={{mt:2}}>If you enter the same applicant information, you can modify or cancel the previously submitted application</Alert> */}
                            
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Container>)
}
export default CourseRegister