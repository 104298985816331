import { CalculateOutlined, Code, LanguageOutlined, PrecisionManufacturing } from "@mui/icons-material"
import { Avatar, Box, Card, CardContent, CardHeader, Grid, Container, Typography } from "@mui/material"
import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR, NORMAL_TEXT_COLOR } from "../../app/config"

const CourseCard = (props) => {
    const { courses, description } = props
    return <Card sx={{ ml: 2, mr: 2, mb: 4 }}>
        
        <CardContent>            
            <Typography variant="subtitle1" color="text.primary">{courses}</Typography>
            <Typography variant="body1" color="text.secondary">{description}</Typography>
        </CardContent>
    </Card>
}
const CourseItem = (props) => {
    const { icon, title } = props
    return <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: 'center', m: 2 }}>
        <Avatar sx={{ bgcolor: "rgba(245, 124, 0, 0.1)", color: LOGO_ICON_COLOR, width: "60px", height: "60px" }}>{icon}</Avatar>
        <Typography variant="h6" sx={{ color: LOGO_TEXT_COLOR, mt: 2 }}>{title}</Typography>
        {/* <Typography variant="body1" sx={{ mt: 2, color: NORMAL_TEXT_COLOR, width: "100%" }} align="center" component={"div"}>{description}</Typography> */}
    </Box>
}
const CourseDetail = () => {    
    const categorys = [
        { icon: <PrecisionManufacturing />, title: "Robotics", courses: "3D Design/Robotics/Coding/ FFL/Vex IQ/Vex V5(VRC)", description: "Comprehensively cultivate children's logical thinking and hands-on abilities, nurture future engineers, and establish a global network of young engineers through team competitions in world-class events."},
        { icon: <Code />, title: "Programming", courses: "Python/Java/CCC/ACSL", description: "Specially developed for children to help them systematically master programming skills and improve logical thinking ability, mastering the language of future technology and grasping future trends."},
        { icon: <CalculateOutlined />, title: "Mathematics", courses: "Advance Math/Competition Math/ AMC/CEMC/CMS", description: "Gain a more comprehensive understanding and improvement in mathematics, broaden thinking, enhance mental agility, appreciate the intrinsic charm of mathematics, and lay a solid foundation for the future."},
        { icon: <LanguageOutlined />, title: "Language", courses:"Public Speaking/Debate/ English/French/Chinese", description: "Develop children's logical and critical thinking skills, improve language organization and expression abilities,encourage extensive reading, independent thinking, expressing opinions, then gaining confidence for future competition."},
    ]
    return (
        <Box sx={{ width: "100%", bgcolor: '#fff' }} id="courses">
            <Container maxWidth="lg" >
                <Box sx={{ flexDirection: "column", display: 'flex', alignItems: "center", pt: 8 }}>
                    <Typography variant="body1" align="center" sx={{ color: NORMAL_TEXT_COLOR }}>COURSES</Typography>                    
                    <Typography variant="h3" align="center" sx={{ color: LOGO_TEXT_COLOR, fontWeight: "bold", mt: 2 }}>Selected high-quality courses</Typography>                    
                    <Box sx={{ width: { md: "50%", xs: "70%" } }}><img src="/course.png" style={{ width: "100%" }} /></Box>
                    <Typography variant="h6" align="center" sx={{ color: NORMAL_TEXT_COLOR, mt: 2, ml: 8, mr: 8 }}>To ensure quality, all our group courses are taught in person, usually small class no more than 6 kids.</Typography>
                </Box>

                <Box sx={{ flexDirection: "column", display: 'flex', alignItems: "center", }}>
                    <Grid container sx={{ mt: 4, }}>
                        {categorys.map((item, idx) => (
                            <Grid key={idx} item xs={12} md={6}>
                                <CourseItem icon={item.icon} title={item.title} key={item.title} />
                                <CourseCard courses={item.courses} description={item.description} key={idx} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>

            </Container>
        </Box>
    )
}
export default CourseDetail